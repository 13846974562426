<template>
    <div class="user-box dropdown">
        <audio  ref="audioRef" id="audioMsg" >
            <source src="https://nn.naiba.online/www.naiba.online/system/message0.mp4" type="audio/mpeg">
        </audio>
        <a class="btn d-flex " data-bs-toggle="dropdown" aria-expanded="false" >
            <el-badge :value="msgCount" :max="9" class="item">
                <img class="" :src=avatarSrc alt="">
            </el-badge>
            <div class="user-info">
                <p class="user-name mb-0">{{ store.state.userinfo.nickname }}</p>
                <p class="designattion mb-0">{{ store.state.userinfo.grade }}</p>
            </div>
        </a>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-if="store.state.islogin"> <router-link to="/bcnaiba/userpage" class="dropdown-item "><i class="bi bi-person-circle"></i>
                            个人资料</router-link></li>
            <li v-if="store.state.islogin"> <router-link to="/bcnaiba/userpage/myscore" class="dropdown-item "><i class="bi bi-star"></i>
                积分 {{ store.state.userinfo.score }}</router-link></li>
            <li  v-if="store.state.islogin"><router-link to="/bcnaiba/userpage/mymessage/list" class="dropdown-item "><i class="bi bi-envelope"></i>
                            我的消息<el-icon style="color:red; "  v-if="msgCount!=''"><Message /></el-icon></router-link></li>
            <li v-if="store.state.islogin"><hr class="dropdown-divider"></li>
            <li v-if="store.state.islogin"><router-link to="/bcnaiba/logout" class="dropdown-item "><i class="bi bi-box-arrow-left"></i>
                退出登录</router-link></li>
            <li v-if="!store.state.islogin"><router-link to="/bcnaiba/login" class="dropdown-item "><i class="bi bi-box-arrow-right"></i>
                快速登录</router-link></li>

        </ul>
    </div>

</template>
<script setup>
    import { Message } from '@element-plus/icons-vue'
    import {ref, watch, onUnmounted} from 'vue'
    import {useStore} from 'vuex'
    import userAPI from "@/api/user"
    import { ElMessage, ElMessageBox  } from 'element-plus'
    import router from '@/router';
    
    
    const avatarSrc = ref('')       //头像文件名src
    const reg_status = ref(false)   //注册状态,默认为未注册，如果登录状态为true,则应该执行一次，依此变量做为判断
    const store = useStore()  //调用全局vuex实例
    const msgCount = ref("")    //未读消息数
    const registerInt = ref(9999)  //定时器ID
    const checkmsgInt = ref(0)  //定时器ID
    const audioRef = ref()  //音乐视频播放

    store.watch(state=>state.userinfo.avatar,(newval,oldval)=>{
         avatarSrc.value = "/img/avatars/avatar-"+store.state.userinfo.avatar+".png"
    },{
        immediate: true,
    })

    store.watch(state=>state.islogin,(newval,oldval)=>{
        if (newval){
            userAPI.getUserInfo().then(res=>{
                console.log('新变化，从服务器获取信息',res.data)
                store.commit('setUserInfo',res.data)
                register()
            }).catch(err=>{
                console.log(err)
            })       
        }else{
            reg_status.value = false    // 设为未登录， 这个将影响是否继续连接服务器
            msgCount.value = ''
        }
    },
    {
        immediate: true,
    }
    )

    watch(reg_status,(newVal,oldVal)=>{
        if (newVal){    //如果为登录，就开始向服务器请求最新信息
            console.log('注册状态有变化！刚刚登录，开始向服务器请求最新动态信息')
            checkmsg()
        }
    })

    const register=()=>{//向服务器发出注册信息
        if (store.state.islogin && !reg_status.value){
            console.log('向服务器发出注册信息: 用户名--tokden--IP--终端 ')
            // if (store.state.ip_addr!=0 && store.state.driver_ID!=0){
            userAPI.registerSession().then(res=>{
                console.log('成功注册====',res.data)
                reg_status.value = true
            }).catch(err=>{
                console.log('错误：',err)
            })
            // }
            // if (registerInt.value!=0){
            //     clearInterval(registerInt.value)
            //     // console.log('清空之前的register定时器1')
            // }
            clearInterval(registerInt.value)//'清空之前的register定时器
            registerInt.value = setInterval(register,5000)
        }
    }

    
    const checkmsg=()=>{
        if (reg_status.value){//如果已经登录，每隔一段时间读取数据更新
            clearInterval(checkmsgInt.value)
            // console.log('=================================================================')
            // console.log('先清除原定时器，id为：',checkmsgInt.value)
            checkmsgInt.value = setInterval(checkmsg,10000)
            // console.log("新的定时器ID:",checkmsgInt.value ,'; 开始检查是否有新的消息')
            userAPI.getstatusInterval().then(res=>{
                console.log("========定时返回数据：",checkmsgInt.value,res.data)
                if (res.data.status==2 || res.data.status==0){                    //您的账号在别处登录，如要正常使用请重新登录！
                    userAPI.logout(store)
                    reg_status.value = false // 注册标志
                    ElMessageBox.alert(res.data.message, '检测到登录状态异常', {
                        // if you want to disable its autofocus
                        autofocus: false,
                        confirmButtonText: 'OK',
                        callback: (action) => {
                            router.push("/bcnaiba/login")
                        },
                    })
                }else if (res.data.status==1){ //正常获取服务器数据和状态
                    store.commit('set_score',res.data.score)
                    if (res.data.newMsg!=0){
                        console.log('profile将更新状态为',res.data.newMsg,'全局状态为：',store.state.noreadmsg)
                        if (res.data.newMsg!=store.state.noreadmsg){ //更新未读消息数量 
                            if (res.data.newMsg>msgCount.value){ //如果是数量增加，则 播放提示音  -- 新消息
                                audioRef.value.currentTime = 0
                                audioRef.value.play()
                            }
                            store.commit('set_noreadmsg',res.data.newMsg)
                        }else{
                            // console.log('它们相等')
                        }
                        msgCount.value = res.data.newMsg
                    }else{
                        msgCount.value = ''
                    }

                }
            }).catch(err=>{
                // console.log(err)
            })
        }else{
            clearInterval(checkmsgInt.value)
        }
    }

    onUnmounted(()=>{
        if (checkmsgInt.value!=0){
            console.log('结束Profile组件。离开前清空消息定时器',checkmsgInt.value)
            clearInterval(checkmsgInt.value)
        }
    })

</script>

<style scoped>
.dropdown-menu{
    font-size: 14px;
}
.dropdown-item{
    display: block;
  width: 100%;
  padding: .25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item .bi{
    padding-right: 15px;
}
li:hover{
    background-color:rgb(191, 238, 255);
}
.user-box img{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 0 solid #e5e5e5;
    padding: 0;
}

.user-info{
    padding-left: 1rem !important;
    box-sizing: border-box;
}
.user-info .user-name {
  font-size: 15px;
  font-weight: 500;
  color: #413c3c;
}

.user-info .designattion {
  font-size: 13px;
  color: #a9a8a8;
}

/* .item {
  margin-top: 10px;
  margin-right: 40px;
} */
</style>