<template>
    <div class="footer flex-row d-flex justify-content-center align-items-center">
        <div class="">
            微望网络提供支持 &copy 2021-{{ year }}
        </div>
    </div>
</template>
<script setup>
var date = new Date();
var year = date.getFullYear()


</script>

<style scoped>
.footer{
    width: 100%;
    height: 50px;
    /* background-color: rgb(188, 188, 188); */
}

</style>