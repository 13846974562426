import "./assets/bootstrap-icons-1.11.1/bootstrap-icons.min.css"
import "bootstrap/dist/css/bootstrap.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import axios from 'axios'

//以下代表解el-table resize问题，参考URL：https://www.jianshu.com/p/2d55b30bb64f
const debounce = (fn, delay) => {
    let timer
     return (...args) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
  }
    
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
     constructor(callback) {
       callback = debounce(callback, 200);
       super(callback);
     }
  }




const app = createApp(App)
// app.config.globalProperties.$axios = axios
app.use(ElementPlus)
app.use(router)
app.use(store)
app.mount('#app')

import "bootstrap/dist/js/bootstrap.js"