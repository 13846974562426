<template>
    <div class="main container ">
        <div class="row ">
            <div class="left col-lg-6 py-5 d-flex flex-column justify-content-center">
                <div class="h1 fw-bold  text-center">
                    <span class="text-primary ">提升青少年</span><span class="text-danger">数字化能力</span>
                </div> 
                <div class="h1 fw-bold text-center mt-2">
                    培养<span class="text-warning">科技</span><span class="text-success">创新</span>型人才
                </div> 
                <div class="text-center mt-2 px-4">
                    <button class="btn btn-outline-primary mx-2 mt-3 px-5" type="submit" @click="signup">加入我们</button>
                    <button class="btn btn-outline-success mx-2 mt-3 px-5" type="submit" >开始学习</button>
                </div>
                <div>
                </div>
            </div>
            <div class="right  col-lg-6 p-5">
                <img src="/img/illustration-1.svg" alt="">
            </div>
        </div>
    </div>
</template>
<script setup>
import router from '@/router';

const signup=()=>{
    router.push("/bcnaiba/signup")
}

</script>

<style scoped>
.main{
    width: 100%;
    min-height: calc(100vh - 76px - 50px);
}
.right img{
    width: 100%;
    overflow-y: hidden;
}
.right{
    padding: 20px;
    
}
.left{
    padding: 20px;
}
</style>