import service from '.';

export default{
    getHello() {//测试用
        return service({
            method: 'GET',
            url: '/',
        });
    },
    signup(params){//注册中
        return service({
            method: 'POST',
            url: '/signup',
            data: params,
        });
    },
    login(params){//用户登录，通过用户名和密码
        return service({
            method: 'POST',
            url: '/login',
            data: params,
        });
    },
    loginWithToken(){//用户登录，通过token
        return service({
            method: 'POST',
            url: '/loginwithtoken',
        });
    },
    Connect_Server(){   //连接服务器，验证token结果'
        return service({
            method: 'POST',
            url: '/test',
        });
    },
    logout(store){  //logout 退出用户
        localStorage.removeItem("jwtToken")
        store.commit("ClearAll")
    },
    getUserInfo(){ //获取用户基本信息
        return service({
            method: 'get',
            url: '/getuserinfo',
        });
    },
    getAllUserInfo(){ //获取所有用户信息info
        return service({
            method: 'get',
            url: 'getAllUserInfo'
        })
    },
    changeUserPassword(params){//修改某个用户的密码
        return service({
            method: 'post',
            url: 'changeUserPassword',
            data: params,
        })
    },
    changeUserActive(params){// 修改某个用户的激状态
        return service({
            method: 'post',
            url: 'changeUserActive',
            data: params,
        })
    },
    saveavatarforme(id){// 修改自己的头像
        return service({
            method: 'post',
            url: 'saveavatarforme/'+id,
        })
    },
    setNickName(newname){// 设置昵称
        return service({
            method: 'POST',
            url: 'savenickname/'+newname,
        })
    },
    registerSession(){//注册用户信息到session和保存用户登录信息
        return service({
            method: 'POST',
            url: 'registerSession',
        })
    },
    getstatusInterval(){//每隔一段时间，从服务器获取用户状态和其他信息
        return service({
            method: 'POST',
            url: 'getstatusInterval',
        })
    },
    changemypassword(params){//修改自己登录的密码
        return service({
            method: 'POST',
            url: 'changemypassword',
            data: params,
        })
    },
}