import { createStore } from 'vuex'
// 参考URL： https://blog.csdn.net/qq_45934504/article/details/123462736

// export default createStore({
//     state:{

//     },
//     mutations:{

//     },
//     actions:{

//     },
//     modules: {

//     }
// })

// 以下是官方代码：
export default createStore({
    state () {
      return {
        count: 0,
        title: '默认标题',
        islogin: false,  //是否登录
        token:'没有设置',        //登录token
        isRZ_admin: false,  //设置证书管理员状态
        permission:null, //设置用户权限
        noreadmsg:0,//未读来信 数量
        temp_list:0,//默认为0，代表无数据，用途：临时存放一个字符串，用于保存资源列表，当使用了此数据后，应马上重新设为0
        userinfo: {//用户信息
          userid:-1,//个人id
          username: "用户",
          nickname: "请先登录",
          grade: '未知等级',
          avatar: 0, //头像图片编号
          signuptime: '0',  //  注册时间
          last_login_time: 0,// 最后一次登录时间
          imagespace:-1,    // 图片可用空间容量
          imagespace_used:-1, //#已使用多少图片空间
          score:0, //默认积分
        },
        newPractice:{//建立配套练习需要的信息
          videoid:0,
          videoTitle:0,
          classid:0,
          content_id:0,
          content_title:0,
        },
        article_temp:{//文章草稿
          title:0,
          content:0
        },
        ResoursesHost:{//资源宿主，用于绑定下载资源
          class_id:0,
          server_id:0,
          list:0,
        },
        ip_addr:0, //IP地址
        driver_ID:0,  //终端类型
        upload_token: '', //上传用token
      }
    },
    mutations: {
      increment (state) {
        state.count++
      },
      setTitle (state,newtitle){
        // state.title = "修改后的标题"
        state.title =  newtitle 
      },
      setToken (state,token){
        state.token =  token
      },
      setLogined (state,status){//设置登录状态
        state.islogin =  status
      },
      setNickName (state,newName){//设置昵称
        state.userinfo.nickname =  newName
      },
      ClearAll (state){//设置为未登录状态
        state.count = 0;
        state.title = '默认标题';
        state.islogin = false;  //是否登录
        state.token ='没有设置';        //登录token
        state.isRZ_admin = false;   //设置证书管理员状态为fasle
        state.permission = null; //清空权限
        state.userinfo.nickname = "请先登录"
        state.userinfo.grade = '未知等级'
        state.userinfo.avatar = 0 //头像编号默认为0
        state.userinfo.last_login_time = '0-0-0'  // 最后一次登录时间
        state.userinfo.imagespace = -1
        state.userinfo.imagespace_used = -1
        state.userinfo.score = 0
        state.userinfo.userid = -1
        state.userinfo.username = '用户'
        state.upload_token = '' //上传用token
        state.noreadmsg = 0
        state.newPractice.videoid = 0
        state.newPractice.content_id = 0
        state.article_temp.title = 0
        state.article_temp.content = 0
        state.ResoursesHost.class_id = 0
        state.ResoursesHost.server_id = 0
        state.ResoursesHost.list = 0
      },
      setRZAdmin(state,status){
        state.isRZ_admin = status   //设置证书管理员状态
      },
      setPermission(state,obj){
        state.permission = obj //  permission设置用户权限
      },
      setUserInfo(state,data){
        state.userinfo.userid = data.user_id
        state.userinfo.nickname = data.nickname        
        state.userinfo.grade = data.grade
        state.userinfo.avatar = data.avatar
        state.userinfo.signuptime = data.create_time
        state.userinfo.username = data.username
        state.userinfo.last_login_time = data.last_login_time2
        state.userinfo.imagespace = data.imagespace
        state.userinfo.imagespace_used = data.imagespace_used
        state.userinfo.score = data.score
      },
      setAvatar(state,data){
        state.userinfo.avatar = data
      },
      setIP_addr(state,ip){//设置IP地址
        state.ip_addr = ip
      },
      setDriver(state,driver_ID){//设置终端设备类型
        state.driver_ID = driver_ID
      },
      setupload_token(state,data){
        state.upload_token = data
      },
      set_imagespace_used(state,data){
        state.userinfo.imagespace_used+=data
      },
      set_noreadmsg(state,data){
        state.noreadmsg=data
      },
      setnewPractice(state,data){
        state.newPractice[data.item]=data.data
      },
      saveArticle(state,data){
        state.article_temp.title = data.title
        state.article_temp.content = data.content
      },
      setResoursesHost(state, data){
        state.ResoursesHost.class_id = data.class_id
        state.ResoursesHost.server_id = data.server_id
        state.ResoursesHost.list = data.list
      },
      set_temp_list(state,data){
        state.temp_list = data 
      },
      set_score(state,data){//设置新的积分值
        state.userinfo.score = data
      },
    }
  })

