import  Home from "../components/bcnaiba/Home.vue"
import HomeHello from '../components/bcnaiba/HomeHello.vue'
import UserPage from '../components/bcnaiba/UserPage.vue'

const HeroChallenge = ()=> import ('@/components/bcnaiba/hero/HeroChallenge.vue')
const MyGame = ()=> import ('@/components/bcnaiba/hero/MyGame.vue')
const ContactUs = ()=> import ('@/components/bcnaiba/contact/ContactUs.vue')
const InitDb = () => import('../components/bcnaiba/InitDb.vue')
const SignUp = () => import('../components/bcnaiba/user/SignUp.vue')
const Login = () => import('../components/bcnaiba/user/Login.vue')
const Logout = () => import('../components/bcnaiba/user/Logout.vue')
// const UserPage = () => import('../components/bcnaiba/UserPage.vue')
const MyInfo =() => import('../components/bcnaiba/user/MyInfo.vue')
const ChangePassword = ()=> import('../components/bcnaiba/user/ChangePassword.vue')
const MysStatistics = ()=> import('../components/bcnaiba/user/MysStatistics.vue')
const AdminStatistics = ()=> import('../components/bcnaiba/statistics/AdminStatistics.vue')
const MySubscribe = ()=> import('../components/bcnaiba/user/MySubscribe.vue')
const Message = ()=> import('../components/bcnaiba/user/Message.vue')
const BrownTeam = ()=> import('../components/bcnaiba/team/BrownTeam.vue')
const PermissionEdit = ()=> import('../components/bcnaiba/team/PermissionEdit.vue')
const UserList = ()=> import('../components/bcnaiba/userlist/UserList.vue')
const MessageList= ()=> import('../components/bcnaiba/user/MessageList.vue')
const MessageNew= ()=> import('../components/bcnaiba/user/MessageNew.vue')
const MessageSend= ()=> import('../components/bcnaiba/user/MessageSend.vue')
const MessageSms= ()=> import('../components/bcnaiba/user/MessageSms.vue')
const ImageUpload = ()=> import ('../components/bcnaiba/user/ImageUpload.vue')
const VideoUpload = ()=> import ('@/components/bcnaiba/videoManage/VideoUpload.vue')
const ScoreManage = ()=> import('@/components/bcnaiba/scoremanage/ScoreManage.vue')
const VideoUploadList = ()=> import('@/components/bcnaiba/videoManage/VideoUploadList.vue')
const CourseList = ()=> import('@/components/bcnaiba/videoManage/CourseList.vue')
const ExerciseList = ()=> import('@/components/bcnaiba/videoManage/ExerciseList.vue')
const CompetitionList = ()=> import('@/components/bcnaiba/videoManage/CompetitionList.vue')
const labelList = ()=> import('@/components/bcnaiba/videoManage/labelList.vue')
const CourseView=()=>import("@/components/bcnaiba/course/CourseView.vue")
const CourseApprove=()=>import("@/components/bcnaiba/course/CourseApprove.vue")
const MyCourseList=()=>import('@/components/bcnaiba/course/MyCourseList.vue')
const CourseContent=()=>import('@/components/bcnaiba/course/CourseContent.vue')
const PracticeList=()=>import("@/components/bcnaiba/practice/PracticeList.vue")
const ArticleList=()=>import("@/components/bcnaiba/practice/ArticleList.vue")
const ResoursesList=()=>import("@/components/bcnaiba/resourses/ResoursesList.vue")
const MyScore=()=>import("@/components/bcnaiba/scoremanage/MyScore.vue")


const bcnaiba = [
    {path: '/bcnaiba', meta:{ title: '编程奶爸' },
    component:Home,
        children:[
            {path:'', components: {
                naiba_main: HomeHello
            }},
            {path:'home', components: {
                naiba_main: HomeHello
            }},
            {path:'init', 
                meta: {title: '编程奶爸 | 初始化网站'},
                components: {
                    naiba_main: InitDb
                }},
            {path:'signup', 
                meta: {title: '编程奶爸 | 新用户注册'},
                components: {
                    naiba_main: SignUp
                }},
            {path:'login', 
                meta: {title: '编程奶爸 | 登录'},
                components: {
                    naiba_main: Login
                }},
            {path:'course', 
                meta: {title: '编程奶爸 | 课程'},
                components: {
                    naiba_main: CourseView
                }
            },
            {path:'herochallenge', 
                meta: {title: '编程奶爸 | 英雄挑战'},
                components: {
                    naiba_main: HeroChallenge
                }
            },
            {path:'mygame/:gameid', 
                meta: {title: '编程奶爸 | 我的挑战赛'},
                components: {
                    naiba_main: MyGame
                }
            },
            {path:'contactus', 
                meta: {title: '编程奶爸 | 联系我们'},
                components: {
                    naiba_main: ContactUs
                }
            },
            {
                path:'mycourse', 
                meta: {title: '编程奶爸 | 我的课程'},
                components: {
                    naiba_main: MyCourseList
                },
                children:[
                    {path:':courseid', component: CourseContent}
                ]
            },
            {path:'Logout', meta: {title: '编程奶爸 | 退出登录'},components: {naiba_main: Logout}} ,
            {path:'userpage', meta: {title: '编程奶爸 | 仪表盘',requiresAuth: true},components: {naiba_main: UserPage},               
                children:[
                    {path:'',redirect: '/bcnaiba/userpage/ad'},
                    {path:'userteam',meta:{title: "编程奶爸 | 团队管理" }, component:  BrownTeam},
                    {path:'permission/:team_id/team/:name',meta:{title: "编程奶爸 | 团队权限" }, component:  PermissionEdit},
                    {path:'userlist',meta:{title: "编程奶爸 | 用户管理" }, component:  UserList},
                    {path:'ad',meta: {title: '编程奶爸 | 个人资料'}, component:  MyInfo},
                    {path:'chw', meta: {title: '编程奶爸 | 安全设置'},component: ChangePassword},
                    {path:'statistics', meta: {title: '编程奶爸 | 统计信息'},component: MysStatistics},
                    {path:'adminstatistics', meta: {title: '编程奶爸 | 统计面板'},component: AdminStatistics},
                    {path:'subscribe', meta: {title: '编程奶爸 | 我的订阅'},component: MySubscribe},
                    {path:'mymessage', meta: {title: '编程奶爸 | 消息管理'},component: Message, 
                    children:[
                            {path:'list',component:MessageList},
                            {path:'new',component:MessageNew},
                            {path:'send',component:MessageSend},
                            {path:'sms',component:MessageSms},
                        ]
                    },
                    {path:'imageupload',meta: {title: '编程奶爸 | 我的图片'}, component: ImageUpload},
                    {path:'videoupload',meta: {title: '编程奶爸 | 课程管理'}, component: VideoUpload,
                        children:[
                            {path:"list",meta: {title: '编程奶爸 | 课程管理 | 视频'},component: VideoUploadList},
                            {path:"courst_list",meta: {title: '编程奶爸 | 课程管理 | 课程'},component: CourseList},
                            {path:"exercise_list",meta: {title: '编程奶爸 | 课程管理 | 练习题'},component: ExerciseList},
                            {path:"competition_list",meta: {title: '编程奶爸 | 课程管理 | 挑战赛'},component: CompetitionList},
                            {path:"label_list",meta: {title: '编程奶爸 | 课程管理 | 标签'},component: labelList},
                            {path:"courseapprove",meta: {title: '编程奶爸 | 课程管理 | 课程订阅'},component: CourseApprove},
                            {path:"practicelist",meta: {title: '编程奶爸 | 课程管理 | 配套练习'},component: PracticeList},
                            {path:"articlelist",meta: {title: '编程奶爸 | 课程管理 | 文章管理'},component: ArticleList},
                            {path:"resourseslist",meta: {title: '编程奶爸 | 课程管理 | 下载资源'},component: ResoursesList},
                        ]
                    },
                    {path:'scoremanage',meta: {title: '编程奶爸 | 积分管理'}, component: ScoreManage},
                    {path:'myscore',meta: {title: '编程奶爸 | 我的积分'}, component: MyScore},
                ]
            },
        ]
    }
]

export default bcnaiba