<template>
<div class="div-body">
    <div class="container main">
        <nav class="navbar navbar-expand-lg navbar-dark ">
            <div class="container-fluid">
                <a class="navbar-brand" href="#"><img src="../assets/naiba_logo2.svg" alt="" style="width:50px; height:50px; opacity: 0.3"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-center">
                    <li class="nav-item">
                        <router-link to="/bcnaiba" class="nav-link"><i class="bi bi-radioactive"></i>
                            编程奶爸</router-link>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="#" @click="router_link_to"><i class="bi bi-android2"></i>
                        机器控制</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="#" @click="router_link_to"><i class="bi bi-microsoft"></i>
                        软件服务</a>
                    </li>
                    <li class="nav-item">
                        <router-link to="/renzheng" class="nav-link"><i class="bi bi-person-vcard"></i>
                            证书查询</router-link>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="#" @click="router_link_to"><i class="bi bi-arrows-angle-contract"></i>
                        留言联系</a>
                    </li>
                    
                </ul>
                </div>
            </div>
        </nav>
    </div>
    <footer class="d-none " :class="{'d-lg-block':showFooter}">
        <div class="text-center ">
            <a href="https://beian.miit.gov.cn/" class=" shadow bg-secondary bg-gradient bg-opacity-10">桂ICP备2021005508号</a>  <br>
            <span class="fs-6 text-primary">copyright&copy微望网络2021-{{ year }}</span> 
        </div>
    </footer>
</div>
</template>
<script setup>
import {ref} from 'vue'
import { ElMessage } from 'element-plus'

const showFooter = ref(true)
const year = ref(2022)

var date = new Date;
year.value = date.getFullYear()
setTimeout(() => {
    showFooter.value = false;
}, 10000);

const router_link_to = ()=> {
    ElMessage({
    message: 'Warning, this is a warning message.',
    type: 'warning',
  })
}
</script>
<style scoped>
.main{
    height: calc(100vh - 50px);
}
footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}
footer span:hover{
    cursor: pointer;
}

.div-body{
  background: url('../assets/homebg.jpg') no-repeat center top;
  margin: 0;
  width: 100%;
  height: 100vh;
}
a{
    text-decoration: none;
}
a:focus{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
</style>