<template>
    <div class="sidebar">
        <el-menu
                class="el-menu-vertical-demo"
                default-active="ad"
                :collapse=iscollapse
                :unique-opened = true
                router
            >
                <el-menu-item  index="#"  class="bg-dark text-warning" @click="iscollapse=!iscollapse">
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="菜单"   placement="right-start" >
                        <el-icon><IconMenu /></el-icon>
                    </el-tooltip>
                    <el-icon v-if="!iscollapse"><IconMenu /></el-icon>
                    <span class="menu-W text-center">菜单</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/userteam" v-if="store.state.permission[0]=='1' || store.state.permission[3]=='1' || store.state.permission[4]=='1'" >
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="团队权限"   placement="right-start" >
                        <el-icon><Promotion /></el-icon>
                    </el-tooltip>
                    <el-icon  v-if="!iscollapse"><Promotion /></el-icon>
                    <span>团队权限</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/userlist" v-if="store.state.permission[7]=='1' || store.state.permission[8]=='1'" >
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="用户管理"   placement="right-start" >
                        <el-icon><Avatar /></el-icon>
                    </el-tooltip>
                    <el-icon  v-if="!iscollapse"><Avatar /></el-icon>
                    <span>用户管理</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/videoupload" v-if="store.state.permission[0]=='1' || store.state.permission[5]=='1'" >
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="课程管理"   placement="right-start" >
                        <el-icon><Film /></el-icon>
                    </el-tooltip>
                    <el-icon  v-if="!iscollapse"><Film /></el-icon>
                    <span>课程管理</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/scoremanage" v-if="store.state.permission[0]=='1' || store.state.permission[6]=='1'" >
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="积分管理"   placement="right-start" >
                        <el-icon><StarFilled /></el-icon>
                    </el-tooltip>
                    <el-icon  v-if="!iscollapse"><StarFilled /></el-icon>
                    <span>积分管理</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/myscore" >
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="我的积分"   placement="right-start" >
                        <el-icon><StarFilled /></el-icon>
                    </el-tooltip>
                    <el-icon  v-if="!iscollapse"><StarFilled /></el-icon>
                    <span>我的积分</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/ad">
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="个人资料"   placement="right-start" >
                        <el-icon><document /></el-icon>
                    </el-tooltip>
                    <el-icon v-if="!iscollapse"><document /></el-icon>
                    <span>个人资料</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/chw" >
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="安全设置"   placement="right-start" >
                        <el-icon><Key /></el-icon>
                    </el-tooltip>
                    <el-icon v-if="!iscollapse"><Key /></el-icon>
                    <span>安全设置</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/statistics" >
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="统计信息"   placement="right-start" >
                        <el-icon><Histogram /></el-icon>
                    </el-tooltip>
                    <el-icon v-if="!iscollapse"><Histogram /></el-icon>
                    <span>统计信息</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/adminstatistics" >
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="统计面板"   placement="right-start" >
                        <el-icon><Histogram /></el-icon>
                    </el-tooltip>
                    <el-icon v-if="!iscollapse"><Histogram /></el-icon>
                    <span>统计面板</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/subscribe">
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="我的订阅"   placement="right-start" >
                        <el-icon><Bell /></el-icon>
                    </el-tooltip>
                    <el-icon v-if="!iscollapse"><Bell /></el-icon>
                    <span>我的订阅</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/mymessage">
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="消息管理"   placement="right-start" >
                        <el-icon><Message /></el-icon>
                    </el-tooltip>
                    <el-icon v-if="!iscollapse"><Message /></el-icon>
                    <span>消息管理</span>
                </el-menu-item>
                <el-menu-item index="/bcnaiba/userpage/imageupload">
                    <el-tooltip v-if="iscollapse" class="box-item"  effect="dark"  content="我的图片"   placement="right-start" >
                        <el-icon><Picture /></el-icon>
                    </el-tooltip>
                    <el-icon v-if="!iscollapse"><Picture /></el-icon>
                    <span>我的图片</span>
                </el-menu-item>
            </el-menu>
    </div>
</template>
<script setup>
import {ref} from 'vue';
import {useStore} from 'vuex'
import {
    Avatar,Promotion,Film,StarFilled,Hide,Histogram,Bell,ChatRound,Message,Picture,Key,
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue'

const iscollapse = ref(true)
const store = useStore()

</script>

<style scoped>
*{
    user-select: none;
}
.sidebar{
    width: 100%;
    height: 100%;
    background-color: rgb(245, 148, 148);
}

.el-menu{
    /* width: 240px; */
    height: 100%;
}

.menu-W{
    width: 160px;
}
</style>