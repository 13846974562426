<template>
  <!-- <RouterView></RouterView> -->
  <router-view></router-view>
</template>

<script setup>
</script>

<style >

</style>
