const  Home =()=>import( "../components/renzheng/Home.vue")
const UserSearch = ()=> import('../components/renzheng/UserSearch.vue')
const zsAdmin = ()=> import('../components/renzheng/Admin.vue')
const Login = ()=> import('../components/renzheng/Login.vue')
const CertificateList = ()=> import('@/components/renzheng/CertificateList.vue')
const CertificateNew = ()=> import('@/components/renzheng/CertificateNew.vue')
const CertificateChange = ()=> import('@/components/renzheng/CertificateChange.vue')
const CertificateCompany = ()=> import('@/components/renzheng/CertificateCompany.vue')

const renzheng = [
    {path: '/renzheng',    meta:{title: "证书查询" },component: Home,
        children:[
            {path:'',redirect: '/renzheng/search'},
            {path:'search',component: UserSearch},
            {path:'login', meta:{title: "证书管理入口" ,}, component: Login},
            {path:'admin', meta:{title: "证书管理" ,requiresZSAuth: true}, component: zsAdmin,
                children:[
                    {path:'',meta:{title: "证书管理 | 证书列表" ,} ,component: CertificateList},
                    {path:'list',meta:{title: "证书管理 | 证书列表" ,} ,component: CertificateList},
                    {path:'new',meta:{title: "证书管理 | 新增证书" ,} ,component: CertificateNew},
                    {path:'company',meta:{title: "证书管理 | 发证机构" ,} ,component: CertificateCompany},
                ]
            },
            // {path:'admin', meta:{title: "证书管理" ,requiresZSAuth: true}, component: CertificateList},
            // {path:'home',component: Home,
            //     children:[
            //         {path: '', redirect:'search'},
            //         {path: 'search', redirect:''},
            //     ]
            // },
        ]
    }
]

export default renzheng