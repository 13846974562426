
import service from '.';

// 这里是处理登录后,给客户端 做的一些设置操作,如hearder,和用户全局变量初始化等.


const setAuthHeader = (token) =>{
    // console.log(service.defaults.headers,'from setAuthHeader.js by 奶爸')
    if (token){
        if (token.length >20){
            service.defaults.headers = {
                Authorization: "Bearer " + token
            };
        }
    }else{
        delete service.defaults.headers.Authorization
    }
    // console.log('完成setAuthHeader，',service.defaults.headers)
}

const setUserSetting = (data,store=null,saveme=false) =>{
    // 用户全局变量初始化状态等.')
    setAuthHeader(data.token);
    if (store!=null){
        store.commit('setToken',data.token)
        store.commit('setLogined',true)
        store.commit('setRZAdmin',true)
        store.commit('setPermission',data.permission)
    }
    if (saveme){
        // console.log('保存token')
        localStorage.setItem("jwtToken",data.token)
    }else{
        // console.log('不保存token')
    }

}


export default setUserSetting ;