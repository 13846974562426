import { createRouter, createWebHistory } from 'vue-router'
import {useStore} from 'vuex'
import userAPI from '@/api/user'; 
import envAPI from '@/api/env'
import setUserSetting from '@/api/setAuthHeader'

import bcnaiba from './bcnaiba'
import renzheng from './renzheng'
import Err404 from '../components/Err404.vue'
import RxHome from '../components/RxHome.vue'

const routes = [
    { path: '', component: RxHome },
    { path: '/', component: RxHome },
    ...bcnaiba,...renzheng
    ,{ path: "/:pathMatch(.*)", component: Err404 },
  ]

const  router = createRouter({
// 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
history: createWebHistory(),
routes, // `routes: routes` 的缩写
})

router.beforeEach((to,from,next)=>{
  const store = useStore()
  // if (store.state.ip_addr==0){
  //   envAPI.set_ip(store)
  // }
  // if (store.state.driver_ID==0){
  //   envAPI.set_driverID(store)
  // }
  console.log('来自:',from.fullPath,'去：',to.fullPath)

  // if (store.state.islogin){
  //   console.log('有store--islogin')
  // }else{
  //   console.log('没有store-！')
  // }
  // const firstLogin = await loginWithToken()  
  ////登录判断 , 先看原状态，没有再看是否有localStorage  ('jwtToken'), 就通过该token登录
  
  if (!store.state.islogin){//如果没有登录，再试试用token登录
    loginWithToken() 
  }

  if (to.meta.title){
    document.title = to.meta.title
  }
  if (to.matched.some(record => record.meta.requiresZSAuth)) {
    // 如果目标路由需要认证，则根据证书管理员登录状态进行处理
    const isZSadmin = checkIfUserIsZSadmin() 
    if (isZSadmin){
      // console.log('是证书管理员')
      next()
    }else{
      // console.log('不是管理员')
      next('/renzheng/login')
    }
  }else{
    if (to.matched.some(record => record.meta.requiresAuth)){//判断是否需要登录，如果需要就判断登录状态，再做决定
      const isLOGIN = checkIfUserIsLoggedIn() 
      if (isLOGIN){
        next()
      }else{
        const token = localStorage.getItem('jwtToken')
        if(token){
          setUserSetting({'token':token})
          userAPI.loginWithToken().then(res=>{
            // console.log('11成功连接服务器',res.data)
            setUserSetting(res.data,store,false)
            next()
          }).catch(err=>{
            // console.log("11token有问题,即将删除此token!",err)
            userAPI.logout(store)
            next('/bcnaiba/login')
          })
        }else{
          // console.log('11没有token')
          next('/bcnaiba/login')
        }
      }
    }else{
      next()
    }
  }

  
})

function checkIfUserIsZSadmin () {
  // TODO: 根据业务逻辑判断用户是否证书管理员已登录
  const store = useStore()
  return store.state.isRZ_admin
}

function checkIfUserIsLoggedIn () {
  // TODO: 根据业务逻辑判断用户是否已登录
  const store = useStore()
  return store.state.islogin
}

function getPermissionbyID (id=1) {
  // TODO: 根据业务逻辑判断用户权限
  const store = useStore()
  return store.state.permission
}

function loginWithToken(){// 如果是登录状态，就返回true, 否则看看如果有localStorage  ('jwtToken'), 就通过该token登录
  const store = useStore()
  const token = localStorage.getItem('jwtToken')

  if (token){
    // console.log("1有token:",token)
    setUserSetting({'token':token})
    userAPI.loginWithToken().then(res=>{
      // console.log('12成功连接服务器00',res.data)
      // res.data.token = token
      // console.log('旧的token信息：',res.data)
      var params = {
        status: res.data.status,
        permission:res.data.permission,
        token: token
      }
      setUserSetting(params,store,false)
      return true
    }).catch(err=>{
      // console.log("12 token有问题,即将删除此token!",err)
      userAPI.logout(store)
      return false
    })
  }else{
    // console.log('00没有token')
    return false
  }
}


export default  router