<template>
    <div class="h3 text-danger text-center mt-5">
        <div>404页面错误,<a  type="button" class="btn text-primary btn-lg" @click="goback">返回</a>
            <router-link class="nav-link inline text-primary fs-5 text-info" to="/">回首页</router-link>
        </div>
    </div>
</template>

<script setup>
import router from '@/router';

const goback = ()=>{
    router.go(-1)
}
</script>